import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import classNames from 'classnames';

import { Hyperlink } from 'mdlkit';
import { MenuInterface } from '../../../../constants/home';

export const MenuWrapper = styled.ul`
  float: left;
  list-style: none;
  padding-left: ${({ theme }) => theme.space.sm - 1}px;
  text-align: left;
`;

export const MenuItem = styled.li`
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  padding: 6px 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGray};
  z-index: 1;
`;

export const SubMenu = styled.ul``;

export const SubMenuItem = styled.li``;

export const MenuItemWithSubMenu = styled(MenuItem)`
  position: relative;

  ${SubMenu} {
    position: absolute;
    top: 70%;
    z-index: 10;
    display: none;
  }

  ${SubMenuItem} {
    width: 100%;
    display: block;
  }

  &.isExpanded,
  &:hover {
    ${SubMenu} {
      display: block;
    }
  }
`;

export const MenuItemLink = styled(Hyperlink)`
  cursor: pointer;
  z-index: 1;
  outline: none;
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: 15px;
  margin-bottom: 5px;
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.lg} {
    border-bottom: none;
    color: ${({ theme }) => theme.colors.darkGray};
    margin-bottom: 0px;
  }
`;

const SubMenuItemLink = styled(Hyperlink)`
  outline: none;
  color: ${({ theme }) => theme.colors.white};
  display: block;
  padding: 14px 18px;
  background: rgba(0, 0, 0, 0.8);
  white-space: nowrap;
  z-index: 1;
`;

interface Props {
  menuOptions: MenuInterface;
}

const Menu: FunctionComponent<Props> = ({ menuOptions }: Props) => {
  const [expandedMenu, setExpandedMenu] = useState<string>('');
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setExpandedMenu('');
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleMenuClick = (menuLabel, e) => {
    e.preventDefault();
    e.stopPropagation();
    setExpandedMenu(menuLabel !== expandedMenu ? menuLabel : '');
  };

  return (
    <MenuWrapper ref={wrapperRef}>
      {menuOptions.map(option => {
        if (option.kind === 'menu') {
          return (
            <MenuItemWithSubMenu
              key={option.label}
              className={classNames({
                isExpanded: expandedMenu === option.label,
              })}
            >
              <MenuItemLink
                href="#"
                onKeyPress={e =>
                  e.charCode === 13 && handleMenuClick(option.label, e)
                }
                onClick={e => handleMenuClick(option.label, e)}
              >
                {option.label}
              </MenuItemLink>
              <SubMenu>
                {option.content.map(submenu => (
                  <SubMenuItem key={submenu.label}>
                    {submenu.kind === 'nextlink' ? (
                      <Link href={submenu.href} passHref>
                        <SubMenuItemLink>{submenu.label}</SubMenuItemLink>
                      </Link>
                    ) : (
                      <SubMenuItemLink href={submenu.href}>
                        {submenu.label}
                      </SubMenuItemLink>
                    )}
                  </SubMenuItem>
                ))}
              </SubMenu>
            </MenuItemWithSubMenu>
          );
        }

        return (
          <MenuItem key={option.label}>
            <MenuItemLink href={option.href}>{option.label}</MenuItemLink>
          </MenuItem>
        );
      })}
    </MenuWrapper>
  );
};

export default Menu;
