import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Hyperlink, ResponsiveBox } from 'mdlkit';
import { Hamburger } from '../../../../core/icons';
import { useTheme } from '../../../../core/hooks';
import Menu, { MenuItem, MenuWrapper, SubMenu } from './Menu';
import { SIGN_IN_URL } from '../../../../constants/login';
import { HEADER_LINKS, REGISTER_LINK } from '../../../../constants/home';

const StyledNav = styled.div`
  text-transform: uppercase;
`;

const BlueHyperlink = styled(Hyperlink)`
  padding: 6px ${({ theme }) => theme.space.xs}px;
  color: ${({ theme }) => theme.colors.darkBlue};
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
  font-size: 11px;
  display: inline-block;

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 14px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 16px;
  }
`;

const PhoneHyperlink = styled(BlueHyperlink)``;

const RegisterWrapper = styled.div`
  position: relative;
  margin-left: auto;
  text-align: left;

  ${({ theme }) => theme.mediaQueries.lg} {
    margin-left: 150px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    text-align: right;
  }

  > ${PhoneHyperlink} {
    display: inline-block;

    ${({ theme }) => theme.mediaQueries.lg} {
      display: none;
    }

    ${({ theme }) => theme.mediaQueries.xl} {
      display: inline-block;
    }
  }
`;

const FloatingMobileMenu = styled.div`
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  z-index: 10;
  display: block;
  height: 380px;
  width: auto;
  overflow: auto;
  padding: 10px 0;
  background: ${({ theme }) => rgba(theme.colors.black, 0.9)};

  ${MenuWrapper} {
    padding: 0px 10px;

    &: ${MenuItem}:first-child {
      padding-top: 0px;
    }
  }

  ${MenuItem} {
    background: transparent;
    display: block;
    white-space: nowrap;
    width: 100%;

    ${SubMenu} {
      position: relative;
      top: auto;
      display: block;
      padding-left: 10px;
    }
  }
`;

const HamburgerMenu = styled('div')`
    position: relative;
    margin-top: -10px;
    margin-right: 10px;

    svg {
        transform: scale(1.1);
    }

    ${({ theme }) => theme.mediaQueries.sm} {
      margin-top: 2px;
      margin-right: 0px;

      svg {
        transform: scale(1);
      }
    }

    &:focus {
      outline: none;
    }
  }
`;

const ResponsiveMenuWrapper = styled(ResponsiveBox)`
  position: absolute;
  top: -15px;
  right: 0;
  z-index: 1;
  margin-left: 5px;

  ${({ theme }) => theme.mediaQueries.sm} {
    position: relative;
    top: auto;
    right: auto;
    float: right;
  }
`;

interface NavProps {
  fromPhysicianRecruitment?: boolean;
}

const Nav: FunctionComponent<NavProps> = ({
  fromPhysicianRecruitment = false,
}: NavProps) => {
  const [showHamburgerMenu, setShowHamburgerMenu] = useState<boolean>(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowHamburgerMenu(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const openMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowHamburgerMenu(true);
  };

  return (
    <StyledNav as="nav">
      {!fromPhysicianRecruitment && (
        <ResponsiveBox hide showOnLg>
          <Menu menuOptions={HEADER_LINKS} />
        </ResponsiveBox>
      )}

      <RegisterWrapper>
        <Link href={SIGN_IN_URL} passHref>
          <BlueHyperlink>Sign In</BlueHyperlink>
        </Link>
        <Link href={REGISTER_LINK} passHref>
          <BlueHyperlink>Create my account</BlueHyperlink>
        </Link>
        <PhoneHyperlink href="tel:1-800-400-6354">
          (800) 400 MDLIVE
        </PhoneHyperlink>

        {!fromPhysicianRecruitment && (
          <ResponsiveMenuWrapper show hideOnLg>
            <HamburgerMenu id="hamburger-menu" ref={wrapperRef}>
              {!showHamburgerMenu && (
                <a href="#" onClick={openMenu}>
                  <Hamburger
                    color={useTheme().colors.darkGray}
                    data-name="empty"
                    size="18px"
                  />
                </a>
              )}

              {showHamburgerMenu && (
                <FloatingMobileMenu>
                  <Menu menuOptions={HEADER_LINKS} />
                </FloatingMobileMenu>
              )}
            </HamburgerMenu>
          </ResponsiveMenuWrapper>
        )}
      </RegisterWrapper>
    </StyledNav>
  );
};

export default Nav;
