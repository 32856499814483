import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from 'mdlkit';

const StyledMain = styled(Box)<BoxProps>`
  background-color: ${({ theme }) => theme.colors.lightGray};
  height: 100%;
  height: auto;
  min-height: 100%;
`;

interface Props {
  children?: ReactNode;
  pt?: string | string[];
}

const Main: FunctionComponent<Props> = ({ children, ...rest }: Props) => (
  <StyledMain as="main" px="sm" {...rest}>
    {children}
  </StyledMain>
);

export default Main;
